import { NavLink } from "react-router-dom";
import "./Cookiebanner.css";

const Cookiebanner = (props) => {
    return (
        <div className="main-cookie-bnr">
            <div className="sub-cookie-bnr">
                <div className="left-cookie">
                    <p>Your privacy is important to us. This website uses cookies to ensure you get the best experience on this website. To learn more about how to control your cookies, the categories of personal information we collect on this website, and how we use that information, please visit our <NavLink to="/privacy-policy">privacy policy</NavLink> or <NavLink to="/external-cookie-policy">cookie policy</NavLink>.</p>
                </div>
                <div className="right-cookie">
                <button onClick={props.hide_Cookie} className="btn cookie">Decline All</button>
                <button onClick={props.hide_Cookie} className="btn cookie">Accept All</button>
                </div>
            </div>
        </div>
    ) 
}
export default Cookiebanner;