import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./Jobs.css";
import { Fragment } from "react"
import Jobsfilter from "../sections/JobsFilter";
import JobsListing from "../sections/JobsListing";
import JobDetail from "../sections/JobDetail";

const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(2);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [jobType, setJobType] = useState();
    const [locationList, setlocationList] = useState();
    const [singleJob, setSingleJob] = useState("");
    const [jobTypeFilter, setJobTypeFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const fetchJobsHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/jobs?per_page=100&acf_format=standard");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            const transformedJobs = data.map(jobData => {
                return {
                key: jobData.id,
                id: jobData.id,
                title: jobData.title.rendered,
                slug: jobData.slug,
                status: jobData.status,
                acf: jobData.acf,
                };
            });
            setJobs(transformedJobs);
            setSingleJob(transformedJobs[0].id);
            // //Job Types Array
            const jobTypes = transformedJobs.map(job => {
                return {
                    job_type: job.acf.job_type,
                }
            });
            // const filteredJobTypes = jobTypes.filter((item) => jobTypes.indexOf(item));
            const filteredJobTypes = [];
            const uniqueJobTypes = jobTypes.filter(element => {
                const isDuplicateType = filteredJobTypes.includes(element.job_type);
                if (!isDuplicateType) {
                    filteredJobTypes.push(element.job_type);
                  return true;
                }
                return false;
            });
            setJobType(filteredJobTypes);

            // Locations list Array
            const locations = transformedJobs.map(job => {
                return {
                    location: job.acf.location,
                }
            });
            const filteredLocations = [];
            const uniqueLocations = locations.filter(element => {
                const isDuplicateLocation = filteredLocations.includes(element.location);
                if (!isDuplicateLocation) {
                    filteredLocations.push(element.location);
                  return true;
                }
                return false;
            });
            setlocationList(filteredLocations);
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const singleJobIDHandler = (id) => {
        setSingleJob(id);
    }

    const jobTypeFilterHandler = (jobTypeValue) => {
        setCurrentPage(1);
        setJobTypeFilter(jobTypeValue);
    }

    const locationFilterHandler = (jobLocationValue) => {
        setCurrentPage(1);
        setLocationFilter(jobLocationValue);
    }

    const searchFilterHandler = (jobSearchValue) => {
        setCurrentPage(1);
        setSearchFilter(jobSearchValue);
    }

    const currentPageHandler = (event) => {
        setCurrentPage(event.target.id);
    }

    
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;

    //Filter Jobs
    let finalJobs = jobs.filter(job => job.acf.job_type.includes(jobTypeFilter) && job.acf.location.includes(locationFilter) && job.title.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase()));
    
    // const currentJobs = finalJobs.slice(indexOfFirstJob, indexOfLastJob);
    
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(finalJobs.length / jobsPerPage); i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li
            key={number}
            id={number}
            onClick={currentPageHandler}
            className={(Number(currentPage) === Number(number) && 'active')}
        >
          {number}
        </li>
      );
    });

    useEffect(() => {
        fetchJobsHandler();
    }, [fetchJobsHandler]);

    useEffect((Callback) => {
        if(finalJobs.length > 0) {
            singleJobIDHandler(finalJobs[0].id); 
        }
    }, []);
    return (
        <Fragment>
            {!isLoading && jobs.length > 0 && 
                <Jobsfilter jobType={jobType} locations={locationList} onjobTypeChange={jobTypeFilterHandler} onJobLocationChange={locationFilterHandler} onJobSearchChange={searchFilterHandler} />
            }
            {isLoading && <p className='message_wrapper'><img className="loading_img" src="/images/loading.gif" /></p>}
            {!isLoading && error && <p className='message_wrapper'>{error}</p>}
            <section id="single-job-sec">
                <div className="container">
                    <div className="single-job-main-col">
                        {!isLoading && jobs.length > 0 && 
                            <Fragment>
                                <div className="single-job-left-col">
                                {finalJobs.length > 0 ?
                                finalJobs.slice(indexOfFirstJob, indexOfLastJob).map((filteredJob, index) => (
                                    filteredJob.id === singleJob ?
                                        <JobsListing active="active" job={filteredJob} onJobClick=
                                        {singleJobIDHandler} />
                                    : 
                                        index === 0 && singleJob === "" ?
                                        <JobsListing active="active" job={filteredJob} onJobClick={singleJobIDHandler} />
                                    :
                                        <JobsListing active="" job={filteredJob} onJobClick={singleJobIDHandler} />
                                ))
                                : <p>No Jobs found.</p>}
                                <ul className='pagination'>
                                    {renderPageNumbers}
                                </ul>
                                </div>
                                <div className="single-job-right-col">
                                    <JobDetail jobId={singleJob} />
                                </div>
                            </Fragment>
                        }
                        {!isLoading && jobs.length === 0 && !error && <p className='message_wrapper'>No Jobs Found.</p>}
                        {isLoading && <p className='message_wrapper'><img className="loading_img" src="/images/loading.gif" /></p>}
                        {!isLoading && error && <p className='message_wrapper'>{error}</p>}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Jobs;