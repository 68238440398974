import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from "react";
import HomeBanner from "../sections/HomeBanner";
import HomeJobs from "../sections/HomeJobs";

const Home = (props) => {

    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchJobsHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/jobs?per_page=6");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            const transformedJobs = data.map(jobData => {
                return {
                key: jobData.id,
                id: jobData.id,
                title: jobData.title.rendered,
                slug: jobData.slug,
                status: jobData.status,
                acf: jobData.acf,
                };
            });
            setJobs(transformedJobs);
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
    fetchJobsHandler();
    }, [fetchJobsHandler]);

    return (
        <Fragment>
            <HomeBanner />
            {!isLoading && jobs.length > 0 && <HomeJobs jobs={jobs} />}
            {!isLoading && jobs.length === 0 && !error && <p className='message_wrapper'>No Jobs Found.</p>}
            {isLoading && <p className='message_wrapper loading_height'><img className="loading_img" src="images/loading.gif" /></p>}
            {!isLoading && error && <p className='message_wrapper'>{error}</p>}
        </Fragment>
    )
}

export default Home;