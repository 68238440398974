import { NavLink } from "react-router-dom";
import "./HomeJobs.css";

const HomeJobs = (props) => {
  return (
    <section id="latest-jobs-sec">
      <div className="container">
        <div className="latest-job-col">
          <div className="title-col">
            <h5 className="title">Latest Jobs</h5>
          </div>

          {props.jobs.map((job) => (
            <div className="job-main-col">
              <div className="job-img-col">
                <div className="job-img">
                  <img src="/images/logo.png" />
                </div>
              </div>
              <div className="job-info-col">
                <p>Tyson Foods</p>
                <h6 dangerouslySetInnerHTML={{ __html: job.title }}></h6>
                <div className="job-extra-info">
                  <img src="/images/map.svg" />
                  <p>{job.acf.location}</p>
                  <div className="job-sec-ex-info">
                    <img src="/images/job-seeker.svg" />
                    <p>{job.acf.job_type}</p>
                  </div>
                </div>
              </div>
              <div className="view-deails-col">
                {/* <a className="btn" href="#"></a> */}
                <NavLink className="btn" to={"/job/" + job.id}>
                  View Details
                </NavLink>
              </div>
            </div>
          ))}

          <div className="view-more-col">
            <NavLink className="btn" to="/jobs">
              View More
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeJobs;
