import { NavLink } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import "./HomeBanner.css";

const HomeBanner = () => {

    const [bannerData, setBannerData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchBannerDataHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/pages?slug=home&acf_format=standard");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            if(data.length > 0) {
                setBannerData(data[0]);
            }
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchBannerDataHandler();
    }, [fetchBannerDataHandler]);

    return (
        <section id="home-bnr">
            {bannerData !== "" && !isLoading &&
                <div className="container">
                    <div className="main-col bnr-main-col">
                        <div className="bnr-left-col">
                            <div dangerouslySetInnerHTML={{ __html: bannerData.acf.banner_title }}></div>
                            <div dangerouslySetInnerHTML={{ __html: bannerData.acf.banner_sub_title }}></div>
                            <NavLink to="/jobs" className="btn bnr-btn">
                                {bannerData.acf.banner_btn_text}
                            </NavLink>
                        </div>
                        <div className="bnr-right-col">
                            <img src={bannerData.acf.banner_image} />
                        </div>
                    </div>
                </div>
            }
            {!isLoading && bannerData === "" && !error && <p className='message_wrapper'>Banner Content not found.</p>}
            {isLoading && <div className="banner_loading"><img className="loading_img" src="images/loading.gif" /></div>}
        </section>
    );
}

export default HomeBanner;