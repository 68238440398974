import { NavLink } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import "./WhoWeAre.css";

const WhoWeAre = () => {
    const [aboutData, setAboutData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAboutDataHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/pages?slug=who-we-are&acf_format=standard");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            if(data.length > 0) {
                setAboutData(data[0]);
            }
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchAboutDataHandler();
    }, [fetchAboutDataHandler]);
    // console.log(aboutData);
    return (
        <div className="container">
            {aboutData !== "" && !isLoading &&
                <div className="about-main">
                <div className="about-sub">
                    <h2 className="about-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_main_title }}></h2>
                    <h6 className="about-sub-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sub_title }}></h6>
                </div>
                <div className="about-main-col1">
                    <div className="about-left-col">
                    <h4 className="about-sec-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec1_left_title }}></h4>
                    <div className="about-sec-desc" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec1_left_desc }}></div>
                    </div>
                    <div className="about-right-col">
                        <img src={aboutData.acf.about_sec1_right_image} />
                    </div>
                </div>
                <div className="about-main-col2">
                    <div className="about-left-col">
                    <h4 className="about-sec-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec2_right_title }}></h4>
                    <div className="about-sec-desc" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec2_right_desc }}></div>
                    <NavLink to="/" className="btn bnr-btn about-sec-btn">
                        {aboutData.acf.about_sec2_right_button_text}
                    </NavLink>
                    </div>
                    <div className="about-right-col">
                        <img src={aboutData.acf.about_sec2_left_image} />
                    </div>
                </div>
                <div className="about-sub">
                    <h2 className="about-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec3_title }}></h2>
                    <h6 className="about-sub-title" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec3_sub_title }}></h6>
                    <div className="about-sec3-desc" dangerouslySetInnerHTML={{ __html: aboutData.acf.about_sec3_desc }}></div>
                </div>
            </div>
            }
            {!isLoading && aboutData === "" && !error && <p className='message_wrapper'>About page Content not found.</p>}
            {isLoading && <div className="banner_loading"><img className="loading_img" src="/images/loading.gif" /></div>}
        </div>
    )
}

export default WhoWeAre;