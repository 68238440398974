import "./JobsFilter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Jobsfilter = (props) => {
  const jobTypeUpdateHandler = (event) => {
    props.onjobTypeChange(event.target.value);
  };

  const jobLocationUpdateHandler = (event) => {
    props.onJobLocationChange(event.target.value);
  };

  let typingTimer = null;

  const jobSearchUpdateHandler = (event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      props.onJobSearchChange(event.target.value);
    }, 200);
  };
  return (
    <section id="filter-sec">
      <div className="container">
        <div className="filter-main-col">
          <div className="fltr-left-col">
            <div className="dropdown-col">
              <h6>Full Time/Part Time</h6>
              <div className="dropdown job-type">
                <select onChange={jobTypeUpdateHandler}>
                  <option value="">Select Job Type</option>
                  {props.jobType.map((jobType) => (
                    <option value={jobType}>{jobType}</option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
            <div className="dropdown-col">
              <div className="dropdown location">
                <h6>Job Location</h6>
                <FontAwesomeIcon icon={faChevronDown} />
                <select onChange={jobLocationUpdateHandler}>
                  <option value="">Select Location</option>
                  {props.locations.map((location) => (
                    <option value={location}>{location}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="fltr-right-col">
            <div className="search-title">
              <h6>Search</h6>
            </div>
            <div className="search-bar-col">
              <img src="/images/search.svg" />
              <input
                onChange={jobSearchUpdateHandler}
                type="search"
                placeholder="Search vacancy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jobsfilter;
