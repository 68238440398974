import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <div className="footer-main-col">
          <div className="footer-menu">
            <NavLink to="/who-we-are">Who We Are</NavLink>
            <NavLink to={"/jobs/"}>Find Jobs</NavLink>
          </div>
          <div className="footer-icons">
            <a href="https://www.facebook.com/TysonFoods/">
              <img src="/images/facebook.png" />
            </a>
            <a href="https://twitter.com/TysonFoods/">
              <img src="/images/twitter.png" />
            </a>
            <a href="https://www.instagram.com/TysonFoods/">
              <img src="/images/insta.png" />
            </a>
            <a href="https://www.youtube.com/user/TysonCommunity">
              <img src="/images/youtube.png" />
            </a>
            <a href="https://www.linkedin.com/company/Tyson-Foods/">
              <img src="/images/linkedin.png" />
            </a>
          </div>
        </div>
      </div>
      <div className="fooetr-copy-right">
        <div className="container">
          <div className="copy-right">
            <div className="copy-right-left">
              <p>Copyright 2022 Tyson Foods, Inc.</p>
            </div>
            <div className="footer_links">
              <NavLink to={"/privacy-policy/"}>Privacy Policy</NavLink>
              <NavLink to={"/external-cookie-policy/"}>External Cookie Policy</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
