import { Route } from 'react-router-dom';
import './App.css';
import Header from './components/sections/Header';
import Home from './components/pages/Home';
import Jobs from './components/pages/Jobs';
import JobSingle from './components/pages/JobSingle';
import Footer from './components/sections/Footer';
import WhoWeAre from './components/pages/WhoWeAre';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import ExternalCookiePolicy from './components/pages/ExternalCookiePolicy';
import Cookiebanner from './components/UI/Cookiebanner';
import { useState } from 'react';


function App() {
  const [cookieDetail, setCookieDetail] = useState(true);
  const hideCookie = () => {
    setCookieDetail(false);
  };
  return (
    <div className="App">
      {/* <PopupForm /> */}
      <Header />
      <Route exact path="/"><Home /></Route>
      <Route exact path="/who-we-are"><WhoWeAre /></Route>
      <Route exact path="/jobs"><Jobs /></Route>
      <Route exact path="/privacy-policy"><PrivacyPolicy /></Route>
      <Route exact path="/external-cookie-policy"><ExternalCookiePolicy /></Route>
      <Route path="/job/:id" ><JobSingle /></Route>
      {cookieDetail ? <Cookiebanner hide_Cookie={hideCookie}/> : null }
      <Footer />
    </div>
  );
}

export default App;