import { Fragment, useRef, useState } from "react";
import "./PopupForm.css";
import Modal from "../UI/Modal";
import emailjs from '@emailjs/browser';

const PopupForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const form = useRef();
    const enteredEmail = useRef();
    
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if(enteredEmail.current.value.trim() !== "") {
            console.log(enteredEmail.current.value);
            if(!isValidEmail(enteredEmail.current.value)) {
                alert('Entered Email is not valid, Please enter valid email to continue.');
            } else {
                setIsLoading(true);
                emailjs.sendForm('service_j92kahy', 'template_6wvmqgb', form.current, 'w5oZHkXLFtZJ81Djy')
                .then((result) => {
                    alert("Thank you for your job application, we will get back to you shortly.")
                    setIsLoading(false);
                    props.onClose();
                }, (error) => {
                    console.log(error.text);
                    setIsLoading(false);
                });
            }
        }
      };

    return (
        <Fragment>
            <Modal onClose={props.onClose}>
                <div className="popup">
                    <h4>Apply Now</h4>
                    <form id="form" encType="multipart/form-data" onSubmit={sendEmail} ref={form} >
                        <input name="to_email" type="hidden" value={props.email} />
                        <input name="job_title" type="hidden" value={props.jobTitle} />
                        <div className="half-col">
                            <label>Name<span>*</span></label>
                            <input name="name" type="text" placeholder="Name" required />
                        </div>
                        <div className="half-col">
                            <label>Surname<span>*</span></label>
                            <input name="surname" type="text" placeholder="Surname" required />
                        </div>
                        <div className="full-col">
                            <label>Phone<span>*</span></label>
                            <input name="phone" type="phone" placeholder="Phone" required />
                        </div>
                        <div className="full-col">
                            <label>E-mail<span>*</span></label>
                            <input name="email" type="text" placeholder="E-mail" required ref={enteredEmail} />
                        </div>
                        <div className="full-col">
                            <label>Motivation</label>
                            <textarea name="motivation" rows="5" placeholder="Tell us what is on your mind..."></textarea>
                        </div>
                        <div className="full-col">
                            <label>CV file upload</label>
                            <input name="cv" id="cv" type="file" />
                        </div>
                        <button className="btn">Send</button>
                    </form>
                    {isLoading && <p className='message_wrapper popup_loading'><img className="loading_img" src="/images/loading.gif" /></p>}
                </div>
            </Modal>
        </Fragment>
    )
}

export default PopupForm;