import { useState, useCallback, useEffect } from "react";
import "./ExternalCookiePolicy.css";

const ExternalCookiePolicy = () => {
    const [cookieData, setCookieData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchCoookieDataHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/pages?slug=external-cookie-policy&acf_format=standard");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            if(data.length > 0) {
                setCookieData(data[0]);
            }
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCoookieDataHandler();
    }, [fetchCoookieDataHandler]);
    return (
        <div className="container">
            {cookieData !== "" && !isLoading &&
                <div className="about-sub">
                <div className="policy-content" dangerouslySetInnerHTML={{ __html: cookieData.content.rendered }}></div>
            </div>
            }
            {!isLoading && cookieData === "" && !error && <p className='message_wrapper'>Cookie page Content not found.</p>}
            {isLoading && <div className="banner_loading"><img className="loading_img" src="/images/loading.gif" /></div>}
        </div>
    )
}

export default ExternalCookiePolicy;