import { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./JobsListing.css";

const JobsListing = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  
  const jobClickHandler = () => {
    props.onJobClick(props.job.id);
  };

  // Window Height
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
  const {innerWidth} = window;
  return {innerWidth};
  }
  // Window Height

  return (
    <Fragment>
      {windowSize.innerWidth < 1100 ? (
        <div className="new-job-main-col">
          <NavLink target="_blank" to={"/job/" + props.job.id}>
            <div className="job-title">
              <p
                className="big-font"
                dangerouslySetInnerHTML={{ __html: props.job.title }}
              ></p>
            </div>
            <div className="job-information">
              <div className="job-info_inner">
                <img src="/images/clock.svg" alt=""/>
                <span>{props.job.acf.work_per_week} hours per week</span>
              </div>
              <div className="job-info_inner">
                <img src="/images/work-experience.svg" alt=""/>
                <span>{props.job.acf.work_experience} years work experience</span>
              </div>
              <div className="job-info_inner">
                <img src="/images/salary.svg" alt=""/>
                <span>{props.job.acf.salary}</span>
              </div>
            </div>
            <div className="job-requirment-main-col">
              <div className="job-require-col">
                <ul>
                  <li>
                    Minimum Qualification :
                    <span>{props.job.acf.minimum_qualification}</span>
                  </li>
                  <li>
                    Experience Level :<span>{props.job.acf.experience_level}</span>
                  </li>
                  <li>
                    Experience Length :<span>{props.job.acf.experience_length}</span>
                  </li>
                  <li>
                    Location :<span>{props.job.acf.location}</span>
                  </li>
                  <li>
                    Application Deadline :
                    <span>{props.job.acf.application_deadline}</span>
                  </li>
                </ul>
              </div>
              <div className="company-logo">
                <div className="job-img">
                  <img src="/images/logo.png" alt="logo"/>
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      ):(
        <div className={"new-job-main-col " + props.active} onClick={jobClickHandler} >  
          <div className="job-title">
            <p
              className="big-font"
              dangerouslySetInnerHTML={{ __html: props.job.title }}
            ></p>
          </div>
          <div className="job-information">
            <div className="job-info_inner">
              <img src="/images/clock.svg" alt=""/>
              <span>{props.job.acf.work_per_week} hours per week</span>
            </div>
            <div className="job-info_inner">
              <img src="/images/work-experience.svg" alt=""/>
              <span>{props.job.acf.work_experience} years work experience</span>
            </div>
            <div className="job-info_inner">
              <img src="/images/salary.svg" alt=""/>
              <span>{props.job.acf.salary}</span>
            </div>
          </div>
          <div className="job-requirment-main-col">
            <div className="job-require-col">
              <ul>
                <li>
                  Minimum Qualification :
                  <span>{props.job.acf.minimum_qualification}</span>
                </li>
                <li>
                  Experience Level :<span>{props.job.acf.experience_level}</span>
                </li>
                <li>
                  Experience Length :<span>{props.job.acf.experience_length}</span>
                </li>
                <li>
                  Location :<span>{props.job.acf.location}</span>
                </li>
                <li>
                  Application Deadline :
                  <span>{props.job.acf.application_deadline}</span>
                </li>
              </ul>
            </div>
            <div className="company-logo">
              <div className="job-img">
                <img src="/images/logo.png" alt="logo"/>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default JobsListing;
