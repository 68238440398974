import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import "./JobDetail.css";
import PopupForm from "./PopupForm";

const JobDetail = (props) => {
  const [job, setJob] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [popupIsShown, setPopupIsShown] = useState(false);

  const showPopupHandler = () => {
    setPopupIsShown(true);
  }

  const hidePopupHandler = () => {
    setPopupIsShown(false);
  }

  const fetchJobHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/jobs/" +
          props.jobId +
          "?acf_format=standard"
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      const data = await response.json();
      const transformedJob = [
        {
          key: data.id,
          id: data.id,
          content: data.content.rendered,
          title: data.title.rendered,
          slug: data.slug,
          status: data.status,
          acf: data.acf,
        },
      ];
      setJob(transformedJob);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, [props]);

  useEffect(() => {
    fetchJobHandler();
  }, [fetchJobHandler, props]);
  return (
    <Fragment>
      {/* {!isLoading && job.length > 0 &&  */}
      {job.length > 0 && (
        <Fragment>
          <div className="single-job-title-col">
            <div className="single-left-col">
              <h5 dangerouslySetInnerHTML={{ __html: job[0].title }}></h5>
              <p
                dangerouslySetInnerHTML={{ __html: job[0].acf.title_desc }}
              ></p>
            </div>
            <div className="single-right-col">
              <NavLink target="_blank" className="export-img" to={"/job/" + job[0].id}>
                <img src="/images/export.png" />
              </NavLink>
              <div className="job-img">
                <img src="/images/logo.png" />
              </div>
              <p className="big-font">Internal recruiter</p>
              <p className="hr">
                {job[0].acf.first_name} {job[0].acf.last_name} -{" "}
                {job[0].acf.position}
              </p>
              <p className="hr-email">{job[0].acf.email_address}</p>
              <p className="hr-num">{job[0].acf.phone_no}</p>
              <button onClick={showPopupHandler}
                className="btn apply-now">
                Apply now
              </button>
            </div>
          </div>
          <div className="job-info-boxes">
            <div className="box">
              <img src="/images/job-seeker.svg" />
              <p>{job[0].acf.job_type}</p>
            </div>
            <div className="box">
              <img src="/images/map.svg" />
              <p>{job[0].acf.location}</p>
            </div>
            <div className="box">
              <img src="/images/clock.svg" />
              <p>Available since {job[0].acf.available_since}</p>
            </div>
          </div>
          <div className="single-job-desc-col">
            <h6>Job description</h6>
            <div className="desc-content">
              <div dangerouslySetInnerHTML={{ __html: job[0].content }}></div>
            </div>
          </div>
          <div className="bottom-btns">
          <NavLink className="btn more-about" to={"/who-we-are/"}>
              More about Tyson
            </NavLink>
            <button onClick={showPopupHandler}
              className="btn apply-now">
              Apply now
            </button>
          </div>
          <div className="ask-question-col">
            <p className="big-font">Questions? Ask {job[0].acf.first_name}</p>
            <div className="single-right-col ask-question-reuriter">
              <div className="job-img">
                <img src="/images/logo.png" />
              </div>
              <div>
                <p className="hr">
                  {job[0].acf.first_name} {job[0].acf.last_name} -{" "}
                  {job[0].acf.position}
                </p>
                <p className="hr-email">{job[0].acf.email_address}</p>
                <p className="hr-num">{job[0].acf.phone_no}</p>
              </div>
            </div>
          </div>
          {popupIsShown &&
            <PopupForm onClose={hidePopupHandler} email={job[0].acf.email_address} jobTitle={job[0].title} />
          }
        </Fragment>
      )}
      {/* } */}
      {/* {!isLoading && job.length === 0 && !error && <p>Job Detail not Found.</p>}
            {isLoading && <p>Loading Job Details...</p>}
            {!isLoading && error && <p>{error}</p>} */}
    </Fragment>
  );
};

export default JobDetail;
