import { NavLink } from "react-router-dom";
import { useState } from "react";
import "./Header.css";

const Header = () => {
    const [mMenu, setmMenu] = useState(false);
    const toggleHandler = () =>{
        setmMenu(!mMenu); 
    }
    return (
        <header id="header">
            <div className="container">
                <div className="main-col">
                    <div className="logo-col">
                        <div className="logo">
                            <NavLink to="/">
                                <img src="/images/logo.png" alt="logo"/>
                            </NavLink>
                        </div>
                    </div>
                    <div className="right-col">
                        <nav className="main_menu">
                            <NavLink to="/who-we-are">
                                Who We Are
                            </NavLink>
                        </nav>
                        <nav>
                            <NavLink activeClassName="active" className="btn-white btn" to="/jobs">Find jobs</NavLink>
                        </nav>
                    </div>
                    <div className="right-col mobile">
                        <div className="h_toggle">
                            <ul className={mMenu ? 'm_line active' : 'm_line'} onClick={toggleHandler}>
                                <li className="line"></li>
                                <li className="line"></li>
                                <li className="line"></li>
                            </ul>
                        </div>
                        <div className={mMenu ? 'main_menu_mobile active' : 'main_menu_mobile'}>
                            <nav>
                                <NavLink to="/who-we-are">
                                    Who We Are
                                </NavLink>
                            </nav>
                            <nav>
                                <NavLink activeClassName="active" className="btn-white btn" to="/jobs">Find jobs</NavLink>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;