import { Fragment } from "react";
import ReactDOM from 'react-dom';
import "./Modal.css";

const Backdrop = (props) => {
    return (
        <div onClick={props.onClose} className="backdrop"></div>
    )
}

const ModelOverlay = (props) => {
    return (
        <div className="modal">
            <div className="content">{props.children}</div>
        </div>
    )
}

const portalElement = document.getElementById('overlays');

const Modal = (props) => {
    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
            {ReactDOM.createPortal(<ModelOverlay>{props.children}</ModelOverlay>, portalElement)}
        </Fragment>
    );
};

export default Modal;