import { useState, useCallback, useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    const [privacyData, setPrivacyData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchPrivacyDataHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch("https://tech-demo.co.in/demo/tysonwp/wp-json/wp/v2/pages?slug=privacy-policy&acf_format=standard");
            if(!response.ok) {
            throw new Error('Something went wrong!');
            }
            const data = await response.json();
            if(data.length > 0) {
                setPrivacyData(data[0]);
            }
        } catch(error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchPrivacyDataHandler();
    }, [fetchPrivacyDataHandler]);
    return (
        <div className="container">
            {privacyData !== "" && !isLoading &&
                 <div className="about-sub">
                    <div className="policy-content" dangerouslySetInnerHTML={{ __html: privacyData.content.rendered }}></div>
                </div>
            }
            {!isLoading && privacyData === "" && !error && <p className='message_wrapper'>Privacy Policy page Content not found.</p>}
            {isLoading && <div className="banner_loading"><img className="loading_img" src="/images/loading.gif" /></div>}
        </div>
    )
}

export default PrivacyPolicy;